<template>
  <div class="terminal-min-height">
    <portal to="header">
      <terminal-header
          :title="$t('Assign')"
          @barcode="handleBarcode"
      />
    </portal>

    <div
        class="text-center q-pa-sm"
        style="height: 190px;"
        v-if="!(this.startPlace && this.endPlace)"
    >
      <img
          src="assets/terminal-icons-md/IMG-0942.png"
          style="width: auto; height: 100%; object-fit: contain;"
      >
    </div>

    <div v-if="this.startPlace && this.endPlace">
      <div
          class="text-center q-pa-sm"
          style="height: 190px;"
      >
        <img
            src="assets/terminal-icons-md/IMG-0951.png"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <h6 class="q-my-none text-center">
        {{ $t('Well done!') }}
      </h6>
    </div>

    <div class="text-center">
      <h6 class="q-my-none q-mr-sm">
        {{ $t('Please scan location') }}
      </h6>

      <q-badge
          :label="dir === 'to' ? $t('CHILD LOCATION') : $t('PARENT LOCATION')"
          color="amber"
          class="text-h6"
      />
    </div>

    <div class="border-top q-pa-xs q-mt-sm border-top--bold" style="max-width: calc(100vw - 16px);">
      <base-place-section
          :data="endPlace"
          :options="endPlaceOptions"
          class="q-mb-sm"
      />

      <div class="row items-center">
        <q-badge
            :label="startPlaceOptions.label"
            :color="startPlace ? startPlaceOptions.selectedColor : startPlaceOptions.defaultColor"
        />

        <div
            v-if="startPlace"
            class="text-caption text-capitalize q-ml-sm"
        ></div>
      </div>

      <div v-if="startPlace" class="row text-center text-subtitle1 no-wrap">
        <div
            class="col q-px-lg q-py-sm"
            style="white-space: pre-wrap !important;"
        >
          <strong>{{ startPlace || 0 }}</strong>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PlaceLinking',
  data () {
    return {
      dir: 'to',
      startPlace: null,
      endPlace: null
    }
  },
  computed: {
    ...mapGetters([
      'terminalSettings'
    ]),
    startPlaceOptions () {
      if (this.dir === 'to') {
        return {
          label: this.$t('CHILD LOCATION'),
          defaultColor: !this.endPlace
              ? 'amber'
              : 'grey-7',
          selectedColor: 'positive'
        }
      }

      return {
        label: this.$t('CHILD LOCATION'),
        defaultColor: 'amber',
        selectedColor: 'positive'
      }
    },
    endPlaceOptions () {
      if (this.dir === 'from') {
        return {
          label: this.$t('PARENT LOCATION'),
          defaultColor: !this.startPlace
              ? 'amber'
              : 'grey-7',
          selectedColor: 'positive'
        }
      }

      return {
        label: this.$t('PARENT LOCATION'),
        defaultColor: 'grey',
        selectedColor: 'positive'
      }
    },
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    scanStartPlace (place) {
      this.toggleDir()

      this.startPlace = place
      this.endPlace = null
    },
    scanEndPlace (place) {
      this.toggleDir()

      this.$service.storagePlace.get(place)
          .then(place => {
            this.endPlace = place

            return this.$service.storagePlace.save({ extId: this.startPlace }, this.endPlace.id).catch(e => {
              console.log(e)
            })
          })
    },
    toggleDir () {
      this.dir = this.dir === 'to'
          ? 'from'
          : 'to'
    },
    handleFinish () {
      this.$emit('finish')
    },
    handleBarcode (barcode) {
      if (this.startPlace && !this.endPlace && barcode.type !== 'S/P') {
        this.addErrorNotification('Please scan location!')
        return
      }

      if (!this.startPlace || (this.startPlace && this.endPlace)) {
        return this.scanStartPlace(barcode.value)
      }

      return this.scanEndPlace(barcode.value)
    }
  }
}
</script>
